.home-background {
    max-width: 100%;
    width: 100%;
}

.testBodyHome{
    margin-top: -40px;
}

hr{
    color: white;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

#btn:hover{
    opacity: 0.5;
}

#btn {
    border-radius: 0;
    border: none;
    padding: 5px;
    margin-right: 5%;
}

.home-btn {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    color: white;
    background-color:#0C3C5B;
}

a{
    text-decoration: none;
    color: white;
}

a:hover {
    color: white;
}

body {
    z-index: 1;
}

.content-container {
    max-width: 100%;
    margin-bottom: 150px;
    height: min-content;
}

.vid-wrapper {
    max-width: 100%;
    overflow: hidden;
}

.first-choice{
    font-family: "Rubik", sans-serif;
    padding-top: 2rem;
}

.what-we-do {
    padding-top: 2rem;
    font-weight: 700;
    color: black;
}

.what-we-do2 {
    font-weight: bold;
}

#carouselExampleIndicators{
    width: 100%;
    /* border-bottom: 3px solid #0C3C5B; */
    padding-top: 6rem;
}


#cta {
    width: 100%;
}

.intro {
    text-align: left;
    color: black;
    font-family: "Rubik", sans-serif;
}

.last-intro {
    padding-bottom: 1rem;
}

.background-ctas {
    padding-bottom: 1.5rem;
    width: 100%;
    background-color: white;
    border-bottom: 3px solid white;
}

.carousel-container {
    display: flex;
    justify-content: center;
    background-color: white;
}

#carousel-inner {
    padding-bottom: 0;
    height: 400px;
}

.home-picture {
    min-width: 100%;
}

.certs {
    width: 100%;
    padding-top: 2rem;
} 

.cert-wrapper {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 10%;
}

.cra { 
    width: 35%;
    padding-bottom: 1.5rem;
}
.bbb {
    width: 40%;
    padding-bottom: 1rem;
}
.qr {
    width: 32.5%;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.oc {
    width: 40%;
    padding-bottom: 1.5rem;
}
.ha {
    width: 35%;
    padding-bottom: 1rem;
}

#cert-exp {
    font-family: "Rubik", sans-serif;
    font-weight: bold;
}

#certifications {
    margin: auto;
    padding-top: 2rem;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    padding-bottom: 2rem;
    border-bottom: 3px solid white;
}
.break {
    margin: auto;
    padding-bottom: 1rem;
}

.title-wrapper {
    background-color: #2A2A2A;
}
.insurance-wrapper {
    background-image: url("storm.webp");
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.insurance {
    color: black;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    width: 100%;
    margin: auto;
}
.insurance-exp {
    color: gray;
    padding: 1rem 1rem 2rem;
    width: 100%;
    margin: auto;
    text-align: left;
}

.insurance-card {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

#i-link {
    padding: 1rem 1rem 1rem;
}
.services-wrapper {
    background-color: #2A2A2A;
}
.services-title {
    padding: 1.5rem 1rem 1rem;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
}
.services-grid {
    gap: 1rem;
    padding: .5rem .5rem .5rem;
}


.tile-link:hover {
    opacity: 0.6;
    -webkit-transform: scale(1.01,1.01);
    -webkit-transition: all 0.3s ease-in-out;
}

#gutter-tile {
    background-image: url("\gutter.jpg");
    background-size: cover;
    height: 400px;
    border-radius: 3px;
    color: white;
}

#roofing-tile {
    background-image: url("\roofing.jpg");
    background-size: cover;
    height: 400px;
    border-radius: 3px;
    color: white;
}

#insurance-tile {
    background-image: url("\insurance.jpg");
    background-size: cover;
    height: 400px;
    border-radius: 3px;
    color: white;
}

#siding-tile {
    background-image: url("\siding.jpg");
    background-size: cover;
    height: 400px;
    border-radius: 3px;
    color: white;
}

.tile-title {
    background-color: rgba(0, 0, 0, 0.6);
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    margin: auto;
    padding: 7rem 1rem 2rem;
}

.last-tile {
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 7rem 1rem 1rem;
}

.tile-exp {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    padding-bottom: 8.1rem;
}

.cert-lit {
    font-family: "Rubik", sans-serif;
    text-align: left;
    padding-left: 2rem;
}

.owens-img {
    padding: 3rem 1rem 2rem;
}

.image-resize{
    padding-top: 5%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
}

.oc-sec > ul {
    width: 90%;
    margin: auto;
}



.content-home {
    text-align: left;
    position: absolute;
    top: 100px;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 1rem;
  }
  .content-header {
      border-bottom: 10px solid #0C3C5B;
  }

@media (min-width: 600px) {
    #myVideo {
        max-width: 100vw;
        width: 89.5vw;
        object-fit: cover;
      }
    .vid-wrapper {
        background-color: #2a2a2a;
    }
    .content-home {
        width: 50%;
    }
    .cert-lit {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 70%;
        margin: auto;
        padding-top: 2rem;
        text-align: left;
    }
    .owens-img {
        padding-top: 6rem;
    }
    
    .oc-sec > ul > li {
        padding-top: 1rem;
        padding-bottom: .75rem;
    }
    .last-tile {
        padding-bottom: 2rem;
    }
    .services-grid {
        color: black;
        font-family: "Rubik", sans-serif;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 1rem;
        padding: 1rem 1rem 1rem;
    }
    .tile-exp {
        padding-bottom: 10.65rem;
    }
    .insurance-card {
        width: 50%;
        margin: auto;
    }

    .insurance-exp {
        width: 50%;
    }
    .insurance {
        width: 50%;
        padding-bottom: 1rem;
    }
    .break {
        width: 100%;
        color: black;
    }
    .bbb {
        width: 25%;
    }
    .cra {
        width: 25%;
    }
    .oc {
        width: 22.5%;
    }
    .ha {
        width: 20%;
    }
    .qr {
        width: 17.5%;
        padding-bottom: 2rem;
    }
    #cta {
        width: 50%;
    }
    #certs {
        width: 60%
    }
    .cert-wrapper {
        width: 100%;
    }
    #cert-exp {
        width: 50%;
        margin: auto;
        text-align: left;
    }
    .carousel-inner {
        width: 100%;
        justify-content: center;
        margin: auto;
        padding-bottom: 1rem;
    }

    .carousel-container{
        justify-content: center;
    }
    #carouselExampleIndicators{
        padding-top: 6.3rem;
    }
    #previous{
        position: absolute;
        left: 0;
        background-color: #0C3C5B;
        border-radius: 5px;
        margin-left: 1rem;
    }
    #next{
        position: absolute;
        right: 0;
        background-color: #0C3C5B;
        border-radius: 5px;
        margin-right: 1rem;
    }
}