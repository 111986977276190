.title {
    font-family: "Rubik", sans-serif;
    font-weight: bold;
}

.about-us{
    padding: 8rem 1rem 1rem;
    text-align: center;
}

#hr {
    padding-bottom: 1rem;
}

.bg-wrapper {
    width: 100%;
    background-image: url("\rh-background.png");
    background-size: cover;
}

.au-title {
    text-align: center;
    color: white;
}

.au-desc {
    padding-bottom: 4rem;
}


.above-title {
    text-align: center;
}

.au-oc-img {
    padding: 1rem 1rem 2rem;
}
.img-display {
    grid-template-columns: none;
    display: grid;
    grid-template-rows: 33% 33% 33%;
    border-top: 3px solid #0C3C5B;
    justify-content: space-between;
    margin-bottom: -120px;
}

.i-1{
    width: 100%;
    height: 100%;
}
.i-2{
    width: 100%;
    height: 100%;
}
.i-3{
    width: 100%;
    height: 100%;
    border-bottom: 3px solid #0C3C5B;
}

#free-in {
    color: #0C3C5B;
}
#rhd-small {
    width: 15%;
}



@media (min-width: 600px) {
    #ins-text {
        padding-bottom: 2rem;
    }
    .img-display {
    border-top: none;
    grid-template-rows: none;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}
.i-1{
    width: 100%;
    height: 100%;
}
.i-2{
    width: 100%;
    height: 100%;
}
.i-3{
    width: 100%;
    height: 100%;
    border-bottom: none;
}
    .about-us {
        color: black;
        width: 50%;
        margin: auto;
        font-family: "rubik", sans-serif;
        text-align: left;
        padding-bottom: 2rem;
    }
}