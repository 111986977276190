#contact-form {
padding: 11rem 1rem 2rem;
border-radius: 3px;
}

#contact-page-wrapper {
    background-image: url("background-image-form.jpg");
    background-size: cover;
}

.contact-form-bg {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem 2rem 2rem;
}

.name-group {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
}

.form-group > input {
    margin-bottom: .75rem;
}

.form-group > select {
    margin-bottom: .75rem;
}

.form-title {
    font-family: "rubik", sans-serif;
    color: white;
    font-weight: bold;
    text-align: left;
    padding-bottom: 1rem;
}

.form-button {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    color: white;
    background-color:#0C3C5B;
    padding: 1rem 1rem 1rem;
    width: 100%;
}

.last-form {
    padding-bottom: 1rem;
}

.phn{
    display: flex;
    align-items: center;
    justify-content: center;
}

.call-btn {
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    background-color: #0C3C5B;
    margin-bottom: 1rem;
    margin-right: 8px;
    margin-left: 8px;
}

.call-btn:hover {
    color:white;
}

.roof-wrap {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    text-align: left;
}

.roof-banner {
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    display: flex;
    color: white;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #393939;
    width: 100%;
    height: 150px;
}
.roof-p {
    font-family: "Rubik", sans-serif;
}

.roof-div {
    width: 50%;
    padding-left: 2rem;
}

.roof-img {
    width: 100%;
}

@media (min-width: 600px){
    #contact-form {
        width: 50%;
        margin: auto;
    }
}