

.body{
	margin-top: 100px;
	background-color: #0C3C5B;
	height: fit-content;
	margin-bottom: -120px;
}

.testHeader {
	color: white;
	text-align: center;
	text-transform: uppercase;
    font-family: 'Noto Sans', sans-serif;
	font-weight: bold;
	position: relative;
	margin: 30px 0 -25px;
}
.testHeader::after {
	content: "";
	width: 140px;
	position: absolute;
	margin: 0 auto;
	height: 3px;
	background: #999;
	left: 0;
	right: 0;
	bottom: -10px;
    opacity: .8;
}
.col-center {
	margin: 0 auto;
	float: none !important;
	height: fit-content;
	
}
.carousel {
	margin: 50px auto;
	margin-bottom: 0;
	padding: 0 70px;
}
.carousel-item {
	color: #999;
	font-size: 14px;
    text-align: center;
	overflow: hidden;
    min-height: fit-content;
	
}


.carousel .overview {	
	font-style: italic;
	
}
.carousel .overview b {
	text-transform: uppercase;
	color: #db584e;
	
}

.carousel-control-next{
	margin-bottom: 200px;
	padding-left: 40px;
}

.carousel-control-prev{
	margin-bottom: 200px;
	padding-right: 40px;
	
}





